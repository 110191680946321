// @import "./utilities/reset";
@import "./variaveis.scss";
@import "./components/modalX";
@import "./components/dataTables/datatables.jquery";
@import "./components/choices";
@import "./components/tableGridJS/index";
@import "./components/filepond";
@import "./components/loader-battle.net.scss";
@import "./components/loader.scss";
@import "./pages/emitir_ordem_compra.scss";
@import "./pages/emitir_relatorio_concorrencia.scss";


@include ModalX("modal_padrao");
